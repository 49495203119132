import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"

import styles from "./recipes.module.css"

export default function Recipes({
    data: { allMarkdownRemark: { edges } },
}) {
    console.log(edges)

    return (
        <Layout mainClass={styles.main}>
            <header className={styles.pageHeader}>
                <h1>Delicious and helthy vegetarian recipes</h1>
                <p className="lead">Browse a selection of vegetarian and vegan recipes you'll find in Vegly</p>
            </header>
            <div className="wrapper">
                <div className={[ styles.recipesList, 'grid' ].join(' ')}>
                    { edges.map(edge => 
                        <div key={edge.node.id} className={styles.recipeCard}>
                            <a href={`/${edge.node.frontmatter.slug}`}>
                                <img src={edge.node.frontmatter.cover} alt={edge.node.frontmatter.title}/>
                                <h3>{edge.node.frontmatter.title}</h3>
                            </a>
                        </div>  
                    )}
                </div>
            </div>
            <div className={styles.actions}>
                <p className="lead">Find more recipes with a Vegly account</p>
                <a href="//app.eatvegly.com/signup" className="btn btn-xl">Sign up for free</a>
            </div>
        </Layout>
    )
}
export const pageQuery = graphql`
    query {
        allMarkdownRemark {
            edges {
                node {
                    id
                    frontmatter {
                        title
                        cover
                        slug
                    }
                }
            }
        }
    }
`